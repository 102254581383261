import React from 'react'
import IDocumentByFileNameRequestModel, { IDocumentByFileName } from '../../../interfaces/IDocumentByFileNameRequestModel';
import DataReadService from '../../../services/DataReadService';
import { getFileExtensionFromFileName } from '../../../helpers/FileHelpers';
import { Utils } from '../../../utilities/utils';
import { useSnackbar } from 'notistack';
import { SnackbarVariantTypes } from '../../../helpers/enums/enums';
import useAsyncFunctionIsLoading from '../../../hooks/useIsLoadingAsync';

export const restrictedFilenameCollision = "A file with that name already exists. Restricted file names must be unique.";

export const useGetFileCollisions = () => {
    const { enqueueSnackbar } = useSnackbar();

    const detectDuplicateFileNames = async (files: File[], folderId: string) => {
        const filesDtos = files.map((file) => {
            return {
                folderId: folderId || '',
                fileName: file.name,
                fileExtension: getFileExtensionFromFileName(file.name),
            } as IDocumentByFileName;
        })

        const model: IDocumentByFileNameRequestModel = {
            files: filesDtos || []
        }
        const dataReadService = new DataReadService();
        return await dataReadService.GetDocumentByFileName(model).then(async (response) => {
            if (response.errorMessages.length) {
                Utils.enqueueMultiLineSnackbar([...response.errorMessages], enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
                return;
            }

            if (response.data.length > 0) {
                const nameCollisions = files?.filter(
                    (file) => {
                        return response.data.find((existingFile) => {
                            return file.name.toLowerCase() === existingFile.displayName.toLowerCase();
                        })
                    }
                )

                return nameCollisions;
            }
            return [];
        });
    }

    const { onTrigger: getDuplicateFiles, loading: loadingNameCollisions } = useAsyncFunctionIsLoading(detectDuplicateFileNames);

    return { getDuplicateFiles, loadingNameCollisions }
}