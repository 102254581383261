import React from "react";
import { FileUploadContext } from "../contexts/FileUploadContext/FileUploadContext";

export const useFileUploadContext = () => {
    const context = React.useContext(FileUploadContext);

    if (context === null) {
        throw new Error('useFileUploadContext must be used with a FileUploadContext.Provider');
    }

    return context;
}