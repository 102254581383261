
export const DownloadFileErrorText: string = "There was an error downloading the file.";
export const DeleteDocumentMultipleTasksWarningText = "This file is attached to multiple requests. Are you sure you want to remove this attachment and permanently delete this file?";
export const DeleteDocumentWarningText = "Are you sure you want to remove this attachment and permanently delete this file?";
export const DeleteCommentWarningText = "Are you sure you want to delete this comment?";
export const FailedToLoadEngagementManagementText = "Failed to load the request list. If you continue to see this error, please contact the Help Desk.";

export const LastActivityTimestampKey = "835E561F-7E00-47AC-914F-615E6417388F_lastActivityTimestamp"
export const WasAutoLoggedOutKey = "835E561F-7E00-47AC-914F-615E6417388F_wasAutoLoggedOut"

export const SystemFolderNames = {
    FinalDocuments: "Final Documents",
    ClientDocuments: "Client Documents",
    Initial: "Initial File Transfer",
}

export const ErrorRequestNotFoundText = "The request was not found.";
