import React from 'react'
import { UploadParams } from './UploadParams';

export interface FileUploadContext {
    files: File[];
    handleFileChange: (files: File[]) => void;
    handleRemoveFile: (file: File) => void;
    cancelUpload: () => void;
    initiateUpload: (params: UploadParams) => Promise<void>;
    uploadNewVersionOnCollision: () => Promise<void>;
    uploadWithRenameOnCollision: () => Promise<void>;
    fileNameCollisions: File[];
    uploadingBackdrop: JSX.Element;
}

export const FileUploadContext = React.createContext<FileUploadContext | null>(null);

