
type ClassValue = string | ConditionalClass;
interface ConditionalClass {
    className: string;
    condition: boolean;
} 

export function classNames(...classes: ClassValue[]): string{
    return classes
        .map((c) => {
            if (typeof c === 'string') {
                return c;
            } 
            else {
                return c.condition ? c.className : '';
            }
        })
        .filter(Boolean)
        .join(' ');
}

export function conditionalClass(className: string, condition: boolean): ConditionalClass {
    return { className, condition };
}
