import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import IFileUploadDialogProps from './IFileUploadDialogProps'
import IPlatformApiPostResult from '../../interfaces/IPlatformApiPostResult';
import { VariantType } from 'notistack';
import MADialogTitle from '../MADialogTitle/MADialogTitle'
import { makeStyles } from "@material-ui/core/styles";
import IContentHierarchy from '../../interfaces/IContentHierarchy';
import RestrictedFileUserAssociationDialogContent from '../RestrictedFileAssociationDialog/RestrictedFileUserAssociationDialogContent';
import { useSelector } from 'react-redux';
import ICurrentUser from '../../interfaces/ICurrentUser';
import FileUploadDropzone from './FileUploadDropzone';
import { createUploadParams, UploadParams } from '../../contexts/FileUploadContext/UploadParams';
import { useFileUploadContext } from '../../hooks/useFileUploadContext';

export const errorUploadingFileDuplicateText = 'File already selected.';
export const errorUploadingFileText = 'There was an error selecting the file.';
export const successfullyUploadedFileText = 'File uploaded successfully.';
export const noFileExtensionText = 'Invalid file type. A file type is required.';
export const restrictedFilenameCollision = "A file with that name already exists. Restricted file names must be unique.";
export const errorSeverity: VariantType = 'error';
export const successSeverity: VariantType = 'success';

const useStyles = makeStyles((theme) => ({
    dropzoneClass: {
        minHeight: "150px",
        maxHeight: "350px",
        padding: theme.spacing(3),
        overflowY: "auto",
        border: 'none',
        backgroundImage: "url(" + "\"data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='4' ry='4' stroke='%23B7C2C2FF' stroke-width='4' stroke-dasharray='10 20' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e" + "\");",
        borderRadius: "4px",
        marginBottom: "15px",
        "& .MuiChip-root": {
            height: "auto",
        },
        "& .MuiChip-label": {
            whiteSpace: "normal",
            overflow: "auto",
            overflowWrap: "anywhere"
        }
    },
    dropzoneParagraphClass: {
        marginTop: '30px'
    },
    singlePreviewGridContainerClass: {
        marginTop: '35px',
        placeContent: "center",
        maxWidth: '500px',
        marginLeft: '15px'
    },
    multiplePreviewGridContainerClass: {
        display: "block",
        textAlignLast: "justify",
        padding: "15px"
    },
    previewChipLabelClass: {
        color: 'black'
    },
    dialogContentClass: {
        "& .MuiDropzoneArea-textContainer": {
            display: 'none'
        }
    },
    backdrop: {
        zIndex: 100,
        color: '#fff',
    },
    label: {
        minWidth: theme.spacing(65),
        margin: "0px 12px 0px 0px",
        justifyContent: "flex-end",
        fontSize: "1.1rem",
    },
}));

function FileUploadDialog(props: IFileUploadDialogProps) {
    const classes = useStyles();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);
    
    const {
        files,
        handleFileChange,
        initiateUpload,
        uploadingBackdrop,
    } = useFileUploadContext();

    const handleClose = () => {
        props.onClose();
        handleFileChange([]);
    }
    React.useEffect(() => {
        handleFileChange([]);
    }, [props.isOpen]);

    const getUploadParams = (permittedUsers: string[]): UploadParams => {
        const { folderId, fileId, uploadRestrictedFiles, withNotifications, uploadWithAttachment } = props;
        if (folderId) {
            let uploadParams = createUploadParams()
                .withFolderId(folderId)
                .withPermittedUsers(permittedUsers)
                .withNewVersionId(fileId || null)
                .withRequestAttachment(uploadWithAttachment?.attachmentTask || null)
                .withRestriction(Boolean(uploadRestrictedFiles))
                .withNotifications(withNotifications, props.showDefaultSuccessToast ?? true)
                .onSuccess(onUploadSuccess);

            if (props.getConfirmationDialogConfig) {
                const dialogConfig = props.getConfirmationDialogConfig();
                uploadParams = dialogConfig ? uploadParams.withConfirmationDialog(dialogConfig) : uploadParams;
            }

            return uploadParams.build();
        }
        throw new Error("FolderId is required to upload files.");
    }

    const onClickUpload = async (permittedUsers: string[] = []) => {
        initiateUpload(getUploadParams(permittedUsers));
    }

    const onUploadSuccess = (result: IPlatformApiPostResult) => {
        if (props.uploadWithAttachment) {
            props.uploadWithAttachment.onAttachmentsUploaded(result);
        }
        else if (props.onFilesUploaded) {
            props.onFilesUploaded(result?.response?.data as IContentHierarchy[]);
        } 
        props.onClose();
    }

    const dropzoneAndLabels = <>
        <FileUploadDropzone
            fileLimit={(props.uploadNewVersion || props.isSingleFile) ? 1 : undefined}
            acceptedFileTypes={props.uploadNewVersion === true && props.fileExt ? [props.fileExt] : undefined}
        />
        <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Client"
            type="text"
            disabled={true}
            defaultValue={props.clientName}
            fullWidth
        />
        <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Engagement"
            type="text"
            disabled={true}
            defaultValue={props.engagementName}
            fullWidth
        />
    </>

    const uploadDisabled = files.length === 0;
    return (
        <>
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="upload-file-dialog" maxWidth={"sm"} fullWidth={props.uploadRestrictedFiles}>
                {uploadingBackdrop}
                {(props.uploadRestrictedFiles && props.folderId && currentUser.isMossAdamsStaff)
                    ?
                    <>
                        <RestrictedFileUserAssociationDialogContent
                            onClose={handleClose}
                            fileUploadProps={{
                                onUpload: onClickUpload,
                                currentUserId: currentUser.userId,
                                folderId: props.folderId,
                            }}
                            className={files ? classes.dialogContentClass : undefined}
                            submitDisabled={uploadDisabled}
                            title='Upload Restricted File(s)'
                        >
                            <>
                                {dropzoneAndLabels}
                            </>
                        </RestrictedFileUserAssociationDialogContent>
                    </>
                    :
                    <>
                        <MADialogTitle id="upload-file-dialog-title" onClose={handleClose}>
                            Upload document(s)
                        </MADialogTitle>
                        <DialogContent className={files ? classes.dialogContentClass : undefined}>
                            {dropzoneAndLabels}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onClickUpload()} color="primary" fullWidth={true} variant="outlined" disabled={uploadDisabled} >
                                Upload
                            </Button>
                        </DialogActions>
                    </>
                }
            </Dialog>
        </>
    );
}

export default FileUploadDialog;