import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Menu, MenuItem, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import IAddTaskDocumentProps from "./IAddTaskDocumentProps";
import IContentHierarchy from "../../interfaces/IContentHierarchy";
import FileSelectDialog from "../FileSelectDialog/FileSelectDialog";
import FileUploadDialog from "../FileUploadDialog/FileUploadDialog";
import RestrictedAttachmentsConfirmDialog from "../RestrictedAttachmentConfirmDialog/RestrictedAttachmentsConfirmDialog";
import IPlatformApiPostResult from "../../interfaces/IPlatformApiPostResult";
import ITaskDocument from "../../interfaces/ITaskDocument";

const useStyles = makeStyles((theme) => ({
    root: {
        "&& .MuiButtonBase-root": {
            backgroundColor: theme.palette.mossadams.darkGreen600,
        },
        "& .Mui-disabled .MuiSvgIcon-root": {
            color: theme.palette.mossadams.gray300
        }
    },
    addIcon: {
        color: theme.palette.primary.contrastText,
    },
}));

const AddTaskDocumentsButton: React.FC<IAddTaskDocumentProps> = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [fileSelectDialogIsOpen, setFileSelectDialogIsOpen] = React.useState<boolean>(false);
    const [restrictedAttachmentsConfirmDialogIsOpen, setRestrictedAttachmentsConfirmDialogIsOpen] = React.useState(false);
    const [chooseFromCurrentEngagement, setChooseFromCurrentEngagement] = React.useState(true);
    const [uploadRestricted, setUploadRestricted] = React.useState(false);
    const [filesToAttach, setFilesToAttach] = React.useState<IContentHierarchy[]>([]);

    const {
        taskNumber,
        title: taskTitle,
        isNew: taskIsNew,
        engagementId,
        clientId,
        isRestricted,
    } = props.task;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e: any) => {
        setAnchorEl(null);
        setUploadRestricted(false);
        e.stopPropagation();
    };

    const handleCopyFromEngagementClick = (e: any) => {
        setAnchorEl(null);
        setChooseFromCurrentEngagement(false);
        setFileSelectDialogIsOpen(true);
        e.stopPropagation();
    };

    const handleAttachExistingDocumentClick = (e: any) => {
        setAnchorEl(null);
        setChooseFromCurrentEngagement(true);
        setFileSelectDialogIsOpen(true);
        e.stopPropagation();
    };

    const handleSelectFileDialogClose = () => {
        setFileSelectDialogIsOpen(false);
    };

    const handleSelectFileDialogSelect = (files: IContentHierarchy[]) => {
        if (props.task.isRestricted) {
            setFilesToAttach(files);
            setRestrictedAttachmentsConfirmDialogIsOpen(true);
        }
        else {
            fileSelectDialogIsOpen && props.onAddTaskDocuments(files);
        }
    }

    const handleUploadNewDocumentClick = (e: any) => {
        setAnchorEl(null);
        props.setAddTaskDocumentFileUploadDialogIsOpen(true);
        e.stopPropagation();
    };

    const handleUploadRestrictedDocumentClick = (e: any) => {
        setAnchorEl(null);
        setUploadRestricted(true);
        props.setAddTaskDocumentFileUploadDialogIsOpen(true);
        e.stopPropagation();
    };

    const handleUploadFileDialogClose = () => {
        props.setAddTaskDocumentFileUploadDialogIsOpen(false);
        setUploadRestricted(false);
    };

    const onUploadAttachmentsSuccess = (result: IPlatformApiPostResult) => {
        const newTaskDocuments = result.response?.data?.createdTaskDocuments as ITaskDocument[];
        props.onAttachmentsUploaded(newTaskDocuments);
    }

    const onConfirmRestrictedAttachments = () => {
        props.onAddTaskDocuments(filesToAttach);
        onCloseRestrictedAttachmentsDialog();
    }

    const onCloseRestrictedAttachmentsDialog = () => {
        setRestrictedAttachmentsConfirmDialogIsOpen(false);
        setFilesToAttach([]);
    }

    return (
        <div className={props.className}>
            <RestrictedAttachmentsConfirmDialog
                isOpen={restrictedAttachmentsConfirmDialogIsOpen}
                onConfirm={onConfirmRestrictedAttachments}
                onClose={onCloseRestrictedAttachmentsDialog}
            />
            <FileSelectDialog
                isOpen={fileSelectDialogIsOpen}
                clientId={clientId as string}
                engagementId={engagementId as string}
                folderId={props.uploadFolderId}
                chooseFromCurrentEngagement={chooseFromCurrentEngagement}
                allowMultiple={props.allowMultipleAttachments}
                taskNumber={taskNumber}
                taskTitle={taskTitle}
                onSelect={handleSelectFileDialogSelect}
                onClose={handleSelectFileDialogClose}
            />
            <FileUploadDialog
                isOpen={props.addTaskDocumentFileUploadDialogIsOpen}
                uploadNewVersion={false}
                isSingleFile={props.allowMultipleAttachments === false}
                clientName={props.clientName}
                engagementName={props.engagementName}
                folderId={props.uploadFolderId}
                onClose={handleUploadFileDialogClose}
                uploadRestrictedFiles={uploadRestricted} 
                withNotifications={false}
                uploadWithAttachment={{
                    attachmentTask: props.task,
                    onAttachmentsUploaded: onUploadAttachmentsSuccess
                }}
            />
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                hidden={props.disabled}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <MenuItem disabled={props.disabled} onClick={handleUploadNewDocumentClick}>Upload New File</MenuItem>
                {isRestricted ? null : <MenuItem disabled={props.disabled} onClick={handleUploadRestrictedDocumentClick}>Upload Restricted File</MenuItem>}
                <MenuItem disabled={props.disabled} onClick={handleAttachExistingDocumentClick}>Attach Existing File(s)</MenuItem>
                <MenuItem disabled={props.disabled} onClick={handleCopyFromEngagementClick}>Copy File from Existing Engagement</MenuItem>
            </Menu>
            <div className={classes.root}>
                <Tooltip title={taskIsNew ? "Please save the request before attaching a file." : "File Options"} >
                    <Button aria-controls="simple-menu" aria-haspopup="true" disabled={props.disabled} onClick={handleClick}>
                        <AddIcon className={classes.addIcon} />
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
};

export default AddTaskDocumentsButton;
