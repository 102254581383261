import { ConfirmDialog } from '../../../components/ConfirmDialog/ConfirmDialog'
import { Typography } from '@material-ui/core';

export interface UploadConfirmationDialogProps {
    config?: UploadConfirmationConfig;
    onConfirm: () => void;
    onCancel: () => void;
    isOpen: boolean;
}

export default function UploadConfirmationDialog(props: UploadConfirmationDialogProps) {
    if (!props.config) return null;

    const onConfirm = () => {
        props.onConfirm();
    }

    const onClose = () => {
        props.onCancel();
    }

    return <>
        <ConfirmDialog
            isOpen={props.isOpen}
            onClose={onClose}
            onConfirm={onConfirm}
            title={props.config.title ?? 'Confirm Upload'}
            confirmButtonText='Yes'
            closeButtonText='No'
            children={<>
                <Typography>
                    {props.config.message}
                </Typography>
            </>}
        />
    </>
}

export interface UploadConfirmationConfig {
    message: string;
    title?: string;
}

export const clientLevelUploadsConfirmation: UploadConfirmationConfig = {
    message: 'Note: these files will be visible to all users with access to this or any other engagement portal within this client. Are you sure you want to proceed?',
    title: 'Upload Files At Client Level?'
}
