import _ from "lodash";
import IDocumentsUploadModel from "../../interfaces/IDocumentsUploadModel";
import IPlatformApiPostResult from "../../interfaces/IPlatformApiPostResult";
import ITask from "../../interfaces/ITask";
import { UploadConfirmationConfig } from "./dialogs/UploadConfirmationDialog";

export interface UploadParams {
    uploadSuccessCallback: (result: IPlatformApiPostResult) => void;
    uploadFailureCallback: (result: IPlatformApiPostResult) => void;
    onEndedCallback: () => void;
    confirmationDialogConfig?: UploadConfirmationConfig;
    uploadModel: IDocumentsUploadModel;
    showDefaultSuccessToast: boolean;
}

const defaultUploadModel: IDocumentsUploadModel = {
    folderId: "",
    permittedUsers: [],
    suppressNotifications: true,
    newVersionDocumentId: null,
    task: null,
    isPrivate: false,
    documentFiles: [],
    overwriteExisting: false
}

export const defaultUploadParams: UploadParams = {
    uploadModel: {...defaultUploadModel},
    uploadSuccessCallback: () => {},
    uploadFailureCallback: () => {},
    onEndedCallback: () => {},
    confirmationDialogConfig: undefined,
    showDefaultSuccessToast: true,
};

export const createUploadParams = (): UploadParamsBuilder => {
    return new UploadParamsBuilder();
};

class UploadParamsBuilder {
    private params: UploadParams;

    constructor() {
        this.params = _.cloneDeep(defaultUploadParams);
    }

    withFolderId(id: string): UploadParamsBuilder {
        this.params.uploadModel.folderId = id;
        return this;
    }

    withRestriction(isRestricted: boolean): UploadParamsBuilder {
        this.params.uploadModel.isPrivate = isRestricted;
        return this;
    }

    withPermittedUsers(users: string[]): UploadParamsBuilder {
        this.params.uploadModel.permittedUsers = users;
        return this;
    }

    withNotifications(sendBackendNotifications: boolean, showDefaultSuccessToast: boolean = true): UploadParamsBuilder {
        this.params.uploadModel.suppressNotifications = !sendBackendNotifications;
        this.params.showDefaultSuccessToast = showDefaultSuccessToast;
        return this;
    }

    withNewVersionId(id: string | null): UploadParamsBuilder {
        this.params.uploadModel.newVersionDocumentId = id;
        return this;
    }

    withRequestAttachment(task: ITask | null): UploadParamsBuilder {
        this.params.uploadModel.task = task;
        return this;
    }

    withConfirmationDialog(config: UploadConfirmationConfig): UploadParamsBuilder {
        this.params.confirmationDialogConfig = config;
        return this;
    }

    onSuccess(callback: (result: IPlatformApiPostResult) => void): UploadParamsBuilder {
        this.params.uploadSuccessCallback = callback;
        return this;
    }

    onFailure(callback: (result: IPlatformApiPostResult) => void): UploadParamsBuilder {
        this.params.uploadFailureCallback = callback;
        return this;
    }
    
    onEnded(callback: () => void): UploadParamsBuilder {
        this.params.onEndedCallback = callback;
        return this;
    }

    build(): UploadParams {
        if (!Boolean(this.params.uploadModel.folderId)) {
            throw new Error('folderId is required');
        }
        return this.params as UploadParams;
    }
}