import React from 'react'
import { useSnackbar } from 'notistack';
import { SnackbarVariantTypes } from '../../helpers/enums/enums';
import { validateUploadSize } from '../../Validators/FileValidators';
import FileDropzone, { IDropzoneProps } from '../common/FileDropzone/FileDropzone';
import { getFileExtensionFromFileName } from '../../helpers/FileHelpers';
import { useFileUploadContext } from '../../hooks/useFileUploadContext';

interface Props {
    fileLimit?: number;
    acceptedFileTypes?: string[];
}

export const errorUploadingFileDuplicateText = 'A file has already been selected with that name.';
export const wrongFileExtensionText = 'Invalid file type. File type must match the existing file.';

export default function FileUploadDropzone(props: Props) {
    const { enqueueSnackbar } = useSnackbar();

    const context = useFileUploadContext();
    const { files, handleFileChange: onAdd, handleRemoveFile: onRemove } = context;

    const getFileSizes = (files: File[]) => {
        return files
            .map(file => file.size)
            .reduce((prev, cur) => prev + cur, 0);
    }

    const allFilesAreDistinct = (filesToAdd: File[]) => {
        const newFileNames = filesToAdd.map((file) => file.name);
        const existingFileNames = files.map((file) => file.name);
        const duplicateFileNames = newFileNames.filter((fileName) => existingFileNames?.includes(fileName));

        if (duplicateFileNames.length > 0) {
            enqueueSnackbar(errorUploadingFileDuplicateText, { variant: SnackbarVariantTypes.Error });
            return false;
        }
        return true;
    }

    const allFilesAreValidTypes = (files: File[]) => {
        if (props.acceptedFileTypes) {
            const fileExtensions = files.map(file => getFileExtensionFromFileName(file.name));
            if (!fileExtensions.every(extension => props.acceptedFileTypes?.includes(extension))) {
                enqueueSnackbar(wrongFileExtensionText, { variant: SnackbarVariantTypes.Error });
                return false;
            }
        }
        return true;
    }

    const fileCountIsWithinLimits = (newFiles: File[]) => {
        const withinLimits = !props.fileLimit || (files.length + newFiles.length) <= props.fileLimit;

        if (!withinLimits) {
            enqueueSnackbar(`Unable to add more than ${props.fileLimit} file(s).`, { variant: SnackbarVariantTypes.Error });
        }
        return withinLimits;
    }

    const uploadSizeIsValid = (newFiles: File[]) => {
        const { isValid, error, } = validateUploadSize(getFileSizes([...files, ...newFiles]));
        if (!isValid) {
            enqueueSnackbar(error, { variant: SnackbarVariantTypes.Error });
        }

        return isValid;
    }

    const ValidateAndAddFiles = (newFiles: File[]) => {
        if (!allFilesAreDistinct(newFiles)
            || !allFilesAreValidTypes(newFiles)
            || !fileCountIsWithinLimits(newFiles)
            || !uploadSizeIsValid(newFiles)) {
            return;
        }

        onAdd([...files, ...newFiles]);
    }

    return (
        <FileDropzone
            onAdd={ValidateAndAddFiles}
            onRemove={onRemove}
            files={files}
        />
    )
}
