import { useEffect } from "react";
import AuthenticationService from "../services/AuthenticationService";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ICurrentUser from "../interfaces/ICurrentUser";
import { setCurrentUser } from "../store/actions";
import { WasAutoLoggedOutKey } from "../helpers/Constants"

/**
 * Hook to handle authentication and logout.
 */
export const useAuthentication = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    useEffect(() => {
        const authenticateUser = async (): Promise<void> => {
            const authenticationService = new AuthenticationService();
            const signedInUser = await authenticationService.loginViaRedirect();
            dispatch(setCurrentUser({ 
                currentUser: {...signedInUser, userTermsAcknowledgmentUpToDate: signedInUser.isMossAdamsStaff ? true : null }, 
            }));
        };

        if (isEmpty(currentUser.accessToken)) {
            sessionStorage.removeItem(WasAutoLoggedOutKey);
            authenticateUser();
        }
    }, []);

    const logout = () => {
        const authenticationService = new AuthenticationService();
        authenticationService.logout(currentUser.account.localAccountId);
    };

    return { 
        currentUser: {...currentUser},
        logout 
    };
}