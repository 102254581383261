import React, { useCallback } from 'react'
import { useFileUpload } from './hooks/useFileUpload';
import IDocumentsUploadModel from '../../interfaces/IDocumentsUploadModel';
import { FileUploadContext } from './FileUploadContext';
import { useGetFileCollisions } from './hooks/useGetFileCollisions';
import ProgressSpinnerBackdrop from '../../components/common/ProgressSpinnerBackdrop/ProgressSpinnerBackdrop';
import SpinnerBackdrop from '../../components/common/SpinnerBackdrop';
import { defaultUploadParams, UploadParams } from './UploadParams';
import _ from 'lodash';
import UploadConfirmationDialog from './dialogs/UploadConfirmationDialog';
import FileNameCollisionDialog from './dialogs/FileNameCollisionDialog';

export interface FileUploadContextProviderProps {
    children: React.ReactNode;
}

interface UploadState {
    params: UploadParams;
    awaitingConfirmation: boolean;
    fileNameCollisions: File[];
}

const defaultUploadState = {
    params: defaultUploadParams,
    awaitingConfirmation: false,
    fileNameCollisions: []
}

export const FileUploadContextProvider = ({ children }: FileUploadContextProviderProps) => {
    const [uploadState, setUploadState] = React.useState<UploadState>(defaultUploadState);

    const {
        files,
        updateSelectedFiles,
        handleUpload,
        uploadProgress,
        isUploading,
        uploadResult,
    } = useFileUpload();

    const { getDuplicateFiles, loadingNameCollisions } = useGetFileCollisions();
    
    const canUploadFiles = () => {
        return files.length 
            && !_.isEqual(defaultUploadParams, uploadState.params) 
            && !uploadState.awaitingConfirmation
            && !isUploading
            && !loadingNameCollisions;
    }

    React.useEffect(() => {
        if (canUploadFiles()) {
            uploadFiles();
        } 
    },[uploadState.params, uploadState.awaitingConfirmation])

    const onAfterUploadAttempted = () => {
        if (uploadResult) {
            if (uploadResult.status) {
                uploadState.params.uploadSuccessCallback(uploadResult);
            }
            else {
                uploadState.params.uploadFailureCallback(uploadResult);
            }
            onUploadEnded();
        }
    }

    React.useEffect(onAfterUploadAttempted, [uploadResult])

    const checkForFileNameCollisions = async () => {
        const nameCollisions = uploadState.params.uploadModel.newVersionDocumentId === null
            ? await getDuplicateFiles(files, uploadState.params.uploadModel.folderId)
            : [];
        if (nameCollisions === undefined) {
            return;
        }

        setUploadState(current => ({
            ...current,
            fileNameCollisions: nameCollisions
        }));
        return nameCollisions;
    }

    const getUploadModel = (overwriteExisting: boolean = true) => {
        const model = {...uploadState.params.uploadModel};
        model.overwriteExisting = overwriteExisting;
        return model;
    }

    const handleRemoveFile = (fileToRemove: File) => {
        const remainingFiles = files.filter((file) => file.name !== fileToRemove.name);
        updateSelectedFiles(remainingFiles);
    }

    const uploadFiles = async () => {
        const nameCollisions = await checkForFileNameCollisions();

        if (nameCollisions && !nameCollisions?.length) {
            await handleUpload(getUploadModel(), uploadState.params.showDefaultSuccessToast);
        }
    }

    const uploadNewVersionOnCollision = async () => {
        if (canUploadFiles() && uploadState.fileNameCollisions.length) {
            await handleUpload(getUploadModel(), uploadState.params.showDefaultSuccessToast);
        }
    }

    const uploadWithRenameOnCollision = async () => {
        if (canUploadFiles() && uploadState.fileNameCollisions.length) {
            await handleUpload(getUploadModel(false), uploadState.params.showDefaultSuccessToast);
        }
    }

    const initiateUpload = async (params: UploadParams, isConfirmed: boolean = false) => {
        const requiresConfirmation = Boolean(params.confirmationDialogConfig) && !isConfirmed;
        
        setUploadState({
            params,
            awaitingConfirmation: requiresConfirmation,
            fileNameCollisions: []
        });
    }

    const confirmUpload = () => {
        setUploadState(current => ({
            ...current,
            awaitingConfirmation: false,
        }));
    }

    const cancelUpload = () => {
        onUploadEnded();
    }

    const onUploadEnded = () => {
        uploadState.params.onEndedCallback();
        setUploadState({...defaultUploadState});
    }

    const uploadingBackdrop = isUploading 
        ? <ProgressSpinnerBackdrop key={`uploadingBackdrop`} progress={uploadProgress} isUploading={isUploading} />
        : <SpinnerBackdrop isActive={loadingNameCollisions} />;

    const value: FileUploadContext = {
        files,
        fileNameCollisions: uploadState.fileNameCollisions,
        handleFileChange: updateSelectedFiles,
        handleRemoveFile,
        initiateUpload,
        cancelUpload,
        uploadNewVersionOnCollision,
        uploadWithRenameOnCollision,
        uploadingBackdrop,
    }

    return <FileUploadContext.Provider value={value}>
        {children}
        <FileNameCollisionDialog />
        <UploadConfirmationDialog isOpen={uploadState.awaitingConfirmation} onConfirm={confirmUpload} onCancel={cancelUpload} config={uploadState.params.confirmationDialogConfig} /> 
    </FileUploadContext.Provider>
}